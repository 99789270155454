@import 'stylesheets/vars';

.dashboard-form {
  max-width: 750px;

  &__row {
    margin-bottom: 29px;
  }

  &__label {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.334769px;
    color: $gray600;
    margin-bottom: 3px;
  }
  &__error {
    color: $red;
    font-size: 12px;
    padding: 4px 0 2px;
  }
}

.mobile-body {
  .dashboard-form {
    padding: 40px 15px 75px;

    &__row {
      margin-bottom: 20px;
      width: 100%;
    }

    &__label {
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;

      letter-spacing: -0.409921px;

      color: #677583;
      margin-bottom: 8px;
    }
  }
}