.opf-selector {
  display: flex;

  &__item {
    height: 103px;

    border: 2px solid #DADADA;
    border-radius: 3px;
    box-sizing: border-box;
    margin-right: 19px;
    display: flex;
    align-items: center;
    overflow: hidden;
    padding: 26px 29px;
    cursor: pointer;
    min-width: 40%;


    &.active {
      border-color: #F6C000;
      .opf-selector {
        &__item {
          &-title {
            color: #000;
          }
        }
      }
    }

    input {
      position: absolute;
      left: -100px;
      top: 0;
      opacity: 0;
      z-index: -10;
    }

    &-content {
      display: flex;
      align-items: center;
    }

    &-icon {

    }

    &-title {
      color: #7B8693;
      font-weight: 500;
      font-size: 20px;
      line-height: 23px;
      /* identical to box height */

      letter-spacing: -0.358681px;
      padding-left: 26px;
    }
  }
}

.mobile-body {
  .opf-selector {
    flex-direction: column;

    &__item {
      width: 100%;
      margin-bottom: 10px;
    }
  }
}
