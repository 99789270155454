@import "stylesheets/vars";

.st-select {
  .ant-select-selection-placeholder {
    color: $gray500;
    font-size: 18px;
    letter-spacing: -0.334769px;
  }

  .ant-select-selector {
    border: 0 !important;
    border-bottom: 1px solid $gray400 !important;
    box-shadow: none !important;
    padding: 0 !important;
    border-radius: 0 !important;
    box-sizing: border-box;
  }

  .ant-select-arrow {
    width: 8px;
    height: 5px;

    .anticon {
      line-height: 5px;
      vertical-align: middle;
    }
  }
}

.mobile-body {
  .st-select {
    .ant-select-selection-placeholder {
      color: $gray500;
      font-size: 18px;
      letter-spacing: -0.334769px;

    }

    .ant-select-selector {
      border: 1px solid $gray400 !important;
      box-shadow: none !important;
      padding: 4px 10px !important;
      border-radius: 3px !important;
      height: 45px!important;
    }

    .ant-select-arrow {
      width: 8px;
      height: 5px;
      top: 56%;

      .anticon {
        line-height: 5px;
        vertical-align: middle;
      }
    }
  }

}
