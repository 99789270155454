@import "stylesheets/vars";

.react-tel-input.st-tel-input {
  .form-control {
    padding-left: 0;
    border-radius: 0;
    border: 0;
    border-bottom: 1px solid $gray400;
    box-sizing: border-box;
    color: $black900;
    filter: none;
    resize: none;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 20px;

    letter-spacing: -0.334769px;
    padding: 4px 0;
  }
  .flag-dropdown {
    display: none;
  }
}

.mobile-body {
  .react-tel-input.st-tel-input {
    .form-control {
      border: 1px solid $gray400;
      padding: 4px 6px;
      width: 100%;
      border-radius: 3px;
    }
  }
}
