.st-modal_registration {
  .opf-selector {
    justify-content: space-between;

    &__item {
      margin: 0;
      width: 48%;
    }
  }
}
