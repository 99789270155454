.st-modal {
  .ant-modal {
    &-content {

    }

    &-header {
      border-bottom: 0;
      margin-bottom: 0;
      padding: 36px 41px 18px;
    }

    &-title {
      font-weight: 500;
      font-size: 35px;
      line-height: 40px;
      letter-spacing: -0.376615px;
      color: #000000;
    }

    &-close-icon {
      svg {
        display: none;
      }
      width: 100%;
      height: 100%;
      background: url('images/icons/close.svg') 50% 50% / 21px 21px no-repeat;
    }

    &-body {
      padding: 18px 41px;
    }

    &-footer {
      padding: 18px 41px 36px;
      border: 0;
      text-align: left;
    }
  }
}

.mobile-body {
  .st-modal {
    width: 100vw!important;
    height: 100%!important;
    position: fixed;
    left: 0;
    top: 0;
    margin: 0;
    transform: none;
    max-width: unset;
    padding: 0;
    box-sizing: border-box;

    .ant-modal {
      &-content {
        border: 0;
        border-radius: 0;
        box-shadow: none;
        height: 100%;
        margin-top: -50px;
        padding: 50px 0;
        box-sizing: content-box;
        display: flex;
        flex-direction: column;
      }

      &-header {
        border-bottom: 0;
        margin-bottom: 0;
        padding: 14px 38px;
        background: #FFFFFF;
        border-bottom: 1px solid #cecece;
        position: relative;
        flex: 1;
      }

      &-title {
        font-size: 16px;
        line-height: 18px;
        text-align: center;
      }

      &-close {
        height: 46px;
        width: 46px;
        left: 0;
        top: 50px;

        &-x {
          width: 100%;
          height: 100%;
        }
      }
      &-close-icon {
        height: 100%;
        background: url('images/mobile/left_arrow.svg') 50% 50% / 24px 24px no-repeat;
      }

      &-body {
        padding: 0;
        height: 100%;
        overflow-y: auto;

        .ant-form {
          padding: 60px 15px;
          width: 100%;
          box-sizing: border-box;
        }
      }

      &-footer {
        padding: 14px;
        border: 0;
        text-align: left;
        flex: 1;
        border-top: 1px solid #cecece;
      }
    }
  }
}
