@import "stylesheets/vars";

.sa-check.ant-checkbox-wrapper {
  margin-right: 10px;
  .ant-checkbox {
    width: 20px;
    height: 20px;
  }

  .ant-checkbox-inner {
    width: 20px;
    height: 20px;
    border: 0.75px solid #B5B5B5;
    box-sizing: border-box;
    border-radius: 3px;
    &:focus {
      border: 0.75px solid #B5B5B5;
    }
  }

  .ant-checkbox-checked {
    &::after {
      border: 0;
    }

    .ant-checkbox-inner {
      background: #FFEBA0;
      border: 0.75px solid #CDB55B;

      &::after {
        background: url('./checked.svg') 0 0 no-repeat;
        border: 0;
        transform: none;
        height: 21px;
        width: 21px;
        top: -4px;
        left: 1px;
      }
    }
  }

  .ant-checkbox-indeterminate {
    .ant-checkbox-inner {
      &::after {
        background-color: $gray500;
      }
    }
  }
}
