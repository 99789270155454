@import "stylesheets/vars";

.ant-btn.st-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 100px;
  border-radius: 3px;
  height: 44px;
  padding: 0 24px;
  border: none;
  color: #fff;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.322813px;
  user-select: none;
  box-sizing: border-box;

  &:hover,
  &:active,
  &:focus {
    color: #fff;
  }

  &.lg {
    height: 60px;
    padding: 0 36px;
  }

  &.sm {
    height: 36px;
    padding: 0 18px;
  }

  &,
  &.primary,
  &.blue {
    background-color: $blue800;

    &:hover,
    &:active,
    &:focus {
      background-color: $blue800;
    }
  }

  &.danger,
  &.red {
    background-color: $red;

    &:hover,
    &:active,
    &:focus {
      background-color: $red;
    }
  }

  &[disabled] {
    &,
    &:hover,
    &:active,
    &:focus {
      color: #fff;
      opacity: .5;
    }
  }

  &.clean {
    &,
    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
      border: 1px solid $gray450;
      color: $blue800;
    }
  }

  &.justify-start {
    justify-content: flex-start;
  }

  svg {
    margin-right: 12px;
  }
}


.mobile-body {
  .mobile-submit {
    &__container {
      background: #FFF;
      border-top: 1px solid #CED9E3;
      padding: 10px 15px;
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      box-sizing: border-box;
    }

    &__btn.save-btn {
      width: 100%;
      margin: 0;
      box-sizing: border-box;
    }
  }
}
