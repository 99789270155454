@import "stylesheets/vars";

.st-input.ant-input {
  &,
  &:focus,
  &:hover {
    border: 0;
    border-bottom: 1px solid $gray400;
    box-sizing: border-box;
    color: $black900;
    filter: none;
    resize: none;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 20px;

    letter-spacing: -0.334769px;
    padding: 4px 0;
    border-radius: 0;
  }
  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #fff inset;
    color: #ccc;
    font-size: 18px;
    line-height: 1.5715;
  }

  // Font styles
  &:-webkit-autofill::first-line {
    font-family: pass, Montserrat, sans-serif;;
    color: #ccc;
    font-size: 16px;
    line-height: 1.5715;
  }

  &::placeholder {
    color: $gray500;
    font-size: 18px;
  }

  &:focus {
    border-bottom: 1px solid $gray400;
    box-shadow: none;
  }
}

.mobile-body {
  .st-input.ant-input {
    &,
    &:focus,
    &:hover {
      background: #FFFFFF;
      border: 1px solid rgba(173, 173, 173, 0.4527);
      box-sizing: border-box;
      border-radius: 3px;
      filter: none;
      resize: none;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      /* identical to box height */
      height: 45px;
      display: flex;
      align-items: center;
      letter-spacing: -0.143473px;

      color: #000000;
      padding: 4px 10px;
    }
    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px #fff inset;
      color: #ccc;
      font-size: 18px;
      line-height: 1.5715;
    }

    // Font styles
    &:-webkit-autofill::first-line {
      font-family: pass, Montserrat, sans-serif;;
      color: #ccc;
      font-size: 16px;
      line-height: 1.5715;
    }

    &::placeholder {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      /* identical to box height */

      display: flex;
      align-items: center;
      letter-spacing: -0.143473px;

      color: #979797;
    }

    &:focus {
      border: 1px solid $gray400;
      box-shadow: none;
    }
  }
}