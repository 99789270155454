.auth-form {
  width: 300px;

  .ant-form-item {
    margin-bottom: 15px;

    &-label {
      margin-bottom: 6px;
      padding: 0;
      label {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.409921px;
        color: #000000;
      }
    }
  }

  .ant-input {
    width: 100%;
    height: 45px;

    border: 1px solid rgba(173, 173, 173, 0.4527);
    border-radius: 4px;
    font-family: 'Plumb';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height, or 133% */

    letter-spacing: -0.409921px;

    color: #979797;
    padding: 10px;
  }
  .ant-input-suffix {
    margin-left: 12px;
  }
  .ant-form-item-explain-success {
    color: #569c31;
  }
  .ant-input-affix-wrapper-status-error.ant-input-affix-wrapper-focused,
  .ant-input-affix-wrapper-focused,
  .ant-input-affix-wrapper-has-feedback {
    padding: 0!important;
    border: 0!important;
    box-shadow: none!important;

    .ant-input {
      padding: 10px;
    }
  }

  .ant-btn {
    width: 100%;
  }
}

.text-blue {
  &,
  &:hover,
  &:visited,
  &:active {
    color: #035AA2;
  }
}
