$maxDesktopWidth: 1282px;

$blue800: #035AA2;
$blue600: #0b6dbf;

$black900: #000;

$gray300: #CED9E3;
$gray400: #DADADA;
$gray450: #ccc;
$gray500: #979797;
$gray600: #75818E;

$red: #ED0000;
